const CustomersSvg = () => {
  return (
    <svg width="139px" height="139px" viewBox="0 0 32.00 32.00" version="1.1">
      <g
        transform="translate(-412.000000, -307.000000)"
        className="sidebarIcons"
      >
        <path d="M443,325 L437,325 C436.447,325 436,325.448 436,326 C436,326.553 436.447,327 437,327 L443,327 C443.553,327 444,326.553 444,326 C444,325.448 443.553,325 443,325 L443,325 Z M443,319 L435,319 C434.447,319 434,319.447 434,320 C434,320.553 434.447,321 435,321 L443,321 C443.553,321 444,320.553 444,320 C444,319.447 443.553,319 443,319 L443,319 Z M443,331 L437,331 C436.447,331 436,331.448 436,332 C436,332.553 436.447,333 437,333 L443,333 C443.553,333 444,332.553 444,332 C444,331.448 443.553,331 443,331 L443,331 Z M435,315 L443,315 C443.553,315 444,314.553 444,314 C444,313.448 443.553,313 443,313 L435,313 C434.447,313 434,313.448 434,314 C434,314.553 434.447,315 435,315 L435,315 Z M432,332.5 C432,334.985 429.802,337 427.091,337 L418.909,337 C416.198,337 414,334.985 414,332.5 L414,329.5 C414,327.374 415.613,325.604 417.775,325.133 C419.25,326.304 421.05,327 423,327 C424.95,327 426.75,326.304 428.225,325.133 C430.387,325.604 432,327.374 432,329.5 L432,332.5 L432,332.5 Z M416,317 C416,312.582 419.134,309 423,309 C426.866,309 430,312.582 430,317 C430,321.418 426.866,325 423,325 C419.134,325 416,321.418 416,317 L416,317 Z M429.951,323.353 C431.23,321.625 432,319.412 432,317 C432,311.478 427.971,307 423,307 C418.029,307 414,311.478 414,317 C414,319.412 414.77,321.625 416.049,323.353 C413.699,324.165 412,326.373 412,329 L412,333 C412,336.313 414.687,339 418,339 L428,339 C431.313,339 434,336.313 434,333 L434,329 C434,326.373 432.301,324.165 429.951,323.353 L429.951,323.353 Z"></path>
      </g>
    </svg>
  );
};

export default CustomersSvg;
