import React from "react";

const WarningError = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3211 13.3039C12.016 13.3039 11.7631 13.0509 11.7631 12.7459V8.83981C11.7631 8.53476 12.016 8.2818 12.3211 8.2818C12.6261 8.2818 12.8791 8.53476 12.8791 8.83981V12.7459C12.8791 13.0509 12.6261 13.3039 12.3211 13.3039Z"
        fill="#FF0C46"
      />
      <path
        d="M12.3211 15.9079C12.2231 15.9089 12.1259 15.8903 12.0352 15.8533C11.9445 15.8162 11.8621 15.7615 11.7928 15.6922C11.7259 15.6178 11.6738 15.5359 11.6292 15.4466C11.5933 15.3568 11.5756 15.2607 11.5771 15.1639C11.5771 14.9705 11.6589 14.777 11.7928 14.6357C12.0681 14.3604 12.5741 14.3604 12.8493 14.6357C12.9833 14.777 13.0651 14.9705 13.0651 15.1639C13.0651 15.2606 13.0428 15.3574 13.0056 15.4466C12.9684 15.5359 12.9163 15.6178 12.8493 15.6922C12.7801 15.7615 12.6977 15.8162 12.607 15.8533C12.5163 15.8903 12.4191 15.9089 12.3211 15.9079Z"
        fill="#FF0C46"
      />
      <path
        d="M12.3211 20C11.8226 20 11.3167 19.8735 10.8703 19.6131L6.45083 17.0611C5.55801 16.5403 5 15.5806 5 14.5464V9.45734C5 8.42316 5.55801 7.46338 6.45083 6.94257L10.8703 4.39061C11.7631 3.8698 12.8717 3.8698 13.7719 4.39061L18.1913 6.94257C19.0842 7.46338 19.6422 8.42316 19.6422 9.45734V14.5464C19.6422 15.5806 19.0842 16.5403 18.1913 17.0611L13.7719 19.6131C13.3255 19.8735 12.8196 20 12.3211 20ZM12.3211 5.11974C12.016 5.11974 11.7036 5.20158 11.4283 5.35782L7.00884 7.90979C6.45827 8.22971 6.11602 8.81748 6.11602 9.45734V14.5464C6.11602 15.1788 6.45827 15.774 7.00884 16.0939L11.4283 18.6459C11.9788 18.9658 12.6633 18.9658 13.2065 18.6459L17.6259 16.0939C18.1765 15.774 18.5187 15.1862 18.5187 14.5464V9.45734C18.5187 8.82492 18.1765 8.22971 17.6259 7.90979L13.2065 5.35782C12.9386 5.20158 12.6261 5.11974 12.3211 5.11974Z"
        fill="#FF0C46"
      />
    </svg>
  );
};

export default WarningError;
