import React from "react";

const Registration = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="25px"
    width="25px"
    version="1.1"
    id="Capa_1"
    viewBox="0 0 502.987 502.987"
  >
    <g>
      <circle fill="#3e54ac" cx="185.994" cy="102.008" r="102.008" />
      <path
        fill="#3e54ac"
        d="M334.444,261.459c-13.244,0-25.95,2.243-37.9,6.191c-25.216-32.335-65.273-53.323-110.55-53.323    c-76.361,0.022-138.226,59.471-138.226,132.898v73.794h172.437c16.221,47.585,61.261,81.969,114.239,81.969    c66.611,0,120.775-54.186,120.775-120.775S401.055,261.459,334.444,261.459z M240.309,382.213    c0-51.899,42.236-94.156,94.135-94.156c51.942,0,94.178,42.257,94.178,94.156c0,51.921-42.236,94.156-94.178,94.156    C282.545,476.369,240.309,434.134,240.309,382.213z"
      />
      <path
        fill="#3e54ac"
        d="M351.269,312.97h-29.983c-0.022,14.366,0,52.158,0,52.158s-32.529,0-48.621,0.043v29.962    c16.092-0.022,48.621-0.022,48.621-0.022s0,37.749-0.022,52.18h29.983c0.022-14.431,0.022-52.18,0.022-52.18s32.507,0,48.599,0    v-29.983c-16.092,0-48.599,0-48.599,0S351.226,327.337,351.269,312.97z"
      />
    </g>
  </svg>
);

export default Registration;
