import React from "react";

const CopyContentSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3334 10.7498V14.2498C13.3334 17.1665 12.1667 18.3332 9.25008 18.3332H5.75008C2.83341 18.3332 1.66675 17.1665 1.66675 14.2498V10.7498C1.66675 7.83317 2.83341 6.6665 5.75008 6.6665H9.25008C12.1667 6.6665 13.3334 7.83317 13.3334 10.7498Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3334 5.74984V9.24984C18.3334 12.1665 17.1667 13.3332 14.2501 13.3332H13.3334V10.7498C13.3334 7.83317 12.1667 6.6665 9.25008 6.6665H6.66675V5.74984C6.66675 2.83317 7.83341 1.6665 10.7501 1.6665H14.2501C17.1667 1.6665 18.3334 2.83317 18.3334 5.74984Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CopyContentSvg;
