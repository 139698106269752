const ExpenseSvg = () => {
  return (
    <svg
      className="sidebarIcons"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path d="M402.95 260.12c0-37.741-79.639-75.796-180.992-75.796-101.361 0-181.002 38.055-181.002 75.796s79.641 75.796 181.002 75.796c101.353 0 180.992-38.055 180.992-75.796zm40.96 0c0 68.608-100.761 116.756-221.952 116.756-121.199 0-221.962-48.148-221.962-116.756s100.763-116.756 221.962-116.756c121.191 0 221.952 48.149 221.952 116.756zm-40.96 372.996c0 37.741-79.639 75.796-180.992 75.796-101.361 0-181.002-38.055-181.002-75.796H-.004c0 68.609 100.763 116.756 221.962 116.756 121.191 0 221.952-48.149 221.952-116.756h-40.96zm0 128.373c0 37.741-79.639 75.796-180.992 75.796-101.361 0-181.002-38.055-181.002-75.796H-.004c0 68.609 100.763 116.756 221.962 116.756 121.191 0 221.952-48.149 221.952-116.756h-40.96zm0-255.677c0 37.741-79.639 75.796-180.992 75.796-101.361 0-181.002-38.055-181.002-75.796H-.004c0 68.609 100.763 116.756 221.962 116.756 121.191 0 221.952-48.149 221.952-116.756h-40.96zm0-117.676c0 37.741-79.639 75.796-180.992 75.796-101.361 0-181.002-38.055-181.002-75.796H-.004c0 68.609 100.763 116.756 221.962 116.756 121.191 0 221.952-48.149 221.952-116.756h-40.96z"></path>
        <path d="M0 260.12v511.713c0 11.311 9.169 20.48 20.48 20.48s20.48-9.169 20.48-20.48V260.12c0-11.311-9.169-20.48-20.48-20.48S0 248.809 0 260.12zm402.95 0v511.713c0 11.311 9.169 20.48 20.48 20.48s20.48-9.169 20.48-20.48V260.12c0-11.311-9.169-20.48-20.48-20.48s-20.48 9.169-20.48 20.48zm492.657 211.06c51.085 17.643 86.056 65.893 86.056 120.817 0 70.578-57.206 127.785-127.785 127.785-47.187 0-89.821-25.783-112.113-66.416-5.44-9.916-17.89-13.545-27.806-8.105s-13.545 17.89-8.105 27.806c29.415 53.616 85.733 87.675 148.024 87.675 93.2 0 168.745-75.545 168.745-168.745 0-72.526-46.173-136.23-113.644-159.533-10.691-3.692-22.351 1.981-26.044 12.672s1.981 22.351 12.672 26.044z"></path>
        <path d="M785.037 429.607c0-70.572-57.21-127.785-127.785-127.785-70.568 0-127.785 57.217-127.785 127.785s57.217 127.785 127.785 127.785c70.575 0 127.785-57.213 127.785-127.785zm40.96 0c0 93.193-75.548 168.745-168.745 168.745-93.19 0-168.745-75.555-168.745-168.745s75.555-168.745 168.745-168.745c93.197 0 168.745 75.552 168.745 168.745z"></path>
      </g>
    </svg>
  );
};

export default ExpenseSvg;
