import React from "react";

const StatsSvg = () => {
  return (
    <svg
      fill=""
      version="1.1"
      id="Capa_1"
      viewBox="0 0 114.595 114.594"
      className="sidebarIcons"
    >
      <path d="M99.287,101.797h-7.121v-4.834c0-0.275-0.225-0.5-0.5-0.5c-0.276,0-0.5,0.225-0.5,0.5v4.834h-9v-4.834    c0-0.275-0.225-0.5-0.5-0.5c-0.276,0-0.5,0.225-0.5,0.5v4.834h-9v-4.834c0-0.275-0.225-0.5-0.5-0.5c-0.276,0-0.5,0.225-0.5,0.5    v4.834h-9v-4.834c0-0.275-0.225-0.5-0.5-0.5c-0.276,0-0.5,0.225-0.5,0.5v4.834h-9v-4.834c0-0.275-0.224-0.5-0.5-0.5    c-0.276,0-0.5,0.225-0.5,0.5v4.834h-9v-4.834c0-0.275-0.224-0.5-0.5-0.5c-0.276,0-0.5,0.225-0.5,0.5v4.834h-9v-4.834    c0-0.275-0.224-0.5-0.5-0.5c-0.276,0-0.5,0.225-0.5,0.5v4.834h-9v-4.834c0-0.275-0.224-0.5-0.5-0.5c-0.276,0-0.5,0.225-0.5,0.5    v4.834H3V90.464h4.016c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5H3v-9h4.016c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5H3    v-9h4.016c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5H3v-9h4.016c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5H3v-9h4.016    c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5H3v-9h4.016c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5H3v-9h4.016    c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5H3v-9h4.016c0.276,0,0.5-0.224,0.5-0.5s-0.224-0.5-0.5-0.5H3v-8.167    c0-0.828-0.672-1.5-1.5-1.5c-0.828,0-1.5,0.672-1.5,1.5v92c0,0.828,0.672,1.5,1.5,1.5h97.787c0.828,0,1.5-0.672,1.5-1.5    S100.115,101.797,99.287,101.797z" />
      <rect x="15.644" y="23.4" width="10.25" height="67.795" />
      <path d="M47.644,55.306c-1.342-1.754-2.43-3.641-3.262-5.611h-6.988v41.5h10.25V55.306z" />
      <path d="M69.145,65.403c-0.332,0.014-0.664,0.035-0.996,0.035c-3.218,0-6.34-0.59-9.254-1.705v27.461h10.25V65.403z" />
      <path d="M91.645,71.571l-9.863-10.008c-0.127,0.078-0.26,0.146-0.387,0.221v29.41h10.25V71.571z" />
      <path d="M113.751,81.944L86.074,53.861c6.94-8.952,6.322-21.913-1.895-30.129c-8.906-8.907-23.4-8.907-32.309,0    c-8.906,8.908-8.906,23.4,0,32.309c8.217,8.217,21.176,8.834,30.13,1.895l27.677,28.081c1.125,1.125,2.947,1.125,4.072,0.001    C114.876,84.893,114.876,83.069,113.751,81.944z M80.109,51.968c-6.662,6.662-17.502,6.663-24.164,0    c-6.662-6.661-6.661-17.501,0-24.162c6.662-6.661,17.501-6.663,24.164,0C86.771,34.467,86.771,45.307,80.109,51.968z" />
      <path d="M68.859,37.443c-2.613-1.081-3.369-1.735-3.369-2.919c0-0.946,0.693-2.052,2.645-2.052c1.837,0,2.993,0.661,3.487,0.943    c0.126,0.073,0.278,0.085,0.417,0.037c0.138-0.05,0.246-0.156,0.299-0.293l0.742-1.96c0.087-0.228-0.004-0.485-0.215-0.608    c-1.063-0.619-2.248-0.974-3.608-1.077v-2.405c0-0.276-0.224-0.5-0.5-0.5h-1.838c-0.276,0-0.5,0.224-0.5,0.5v2.583    c-2.735,0.604-4.478,2.603-4.478,5.188c0,3.119,2.504,4.533,5.203,5.595c2.159,0.873,3.039,1.762,3.039,3.064    c0,1.439-1.218,2.406-3.027,2.406c-1.4,0-2.856-0.434-3.994-1.19c-0.127-0.086-0.287-0.106-0.434-0.059    c-0.147,0.047-0.263,0.161-0.313,0.306l-0.712,1.985c-0.075,0.211-0.002,0.446,0.181,0.576c1.04,0.742,2.696,1.264,4.327,1.377    v2.516c0,0.276,0.225,0.5,0.5,0.5h1.868c0.276,0,0.5-0.224,0.5-0.5v-2.671c2.819-0.631,4.686-2.778,4.686-5.454    C73.764,40.604,72.296,38.843,68.859,37.443z" />
    </svg>
  );
};

export default StatsSvg;
