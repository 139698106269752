import React from "react";

const CompaniSvg = () => {
  return (
    <svg
      className="companySvg"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
      height="20px"
      width="20px"
      version="1.1"
      id="Layer_1"
      viewBox="0 0 472.615 472.615"
    >
      <circle cx="236.308" cy="142.868" r="70.203" />

      <path d="M325.514,234.831l-16.542-4.923l-72.665,72.763l-72.665-72.763l-16.542,4.923c-42.831,12.898-71.582,51.495-71.582,96.197    v82.511h321.575v-82.511C397.095,286.326,368.345,247.729,325.514,234.831z" />

      <path d="M319.311,59.077c-11.9,0-23.306,3.208-33.317,8.938c24.209,16.125,40.208,43.645,40.208,74.849    c0,18.496-5.625,35.697-15.239,50.004c2.762,0.348,5.542,0.609,8.348,0.609c37.12,0,67.249-30.129,67.249-67.249    C386.56,89.205,356.431,59.077,319.311,59.077z" />

      <path d="M404.283,213.169l-16.049-4.726l-23.555,23.556c32.136,21.917,52.11,58.372,52.11,99.029V384h55.827v-78.966    C472.615,262.4,445.145,225.477,404.283,213.169z" />

      <path d="M186.621,68.015c-10.01-5.73-21.416-8.938-33.316-8.938c-37.12,0-67.249,30.128-67.249,67.151    c0,37.12,30.129,67.249,67.249,67.249c2.805,0,5.586-0.262,8.347-0.609c-9.614-14.306-15.239-31.508-15.239-50.004    C146.413,111.66,162.412,84.14,186.621,68.015z" />

      <path d="M84.382,208.443l-15.951,4.726h-0.099C27.471,225.477,0,262.4,0,305.034V384h55.827v-52.972    c0-40.666,19.984-77.128,52.104-99.036L84.382,208.443z" />
    </svg>
  );
};

export default CompaniSvg;
