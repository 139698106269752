import React from "react";

const WarningIcone = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.19567 15.7842L8.4861 2.00206C8.63173 1.72683 8.84963 1.49651 9.11638 1.33586C9.38312 1.17522 9.68861 1.09033 10 1.09033C10.3114 1.09033 10.6169 1.17522 10.8836 1.33586C11.1504 1.49651 11.3683 1.72683 11.5139 2.00206L18.8043 15.7842C18.9412 16.0443 19.0083 16.3354 18.9992 16.6292C18.9901 16.9229 18.905 17.2093 18.7523 17.4604C18.5996 17.7115 18.3845 17.9188 18.1278 18.062C17.8712 18.2052 17.5819 18.2795 17.288 18.2777H2.70875C2.41514 18.279 2.1262 18.2042 1.87003 18.0608C1.61385 17.9173 1.39916 17.71 1.24684 17.459C1.09453 17.208 1.00976 16.9218 1.00079 16.6283C0.991827 16.3349 1.05896 16.0441 1.19567 15.7842V15.7842Z"
        stroke="#FFA726"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M9.99854 6.82129V11.7312"
        stroke="#FFA726"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M9.99848 15.8226C10.6764 15.8226 11.226 15.2731 11.226 14.5952C11.226 13.9172 10.6764 13.3677 9.99848 13.3677C9.32056 13.3677 8.771 13.9172 8.771 14.5952C8.771 15.2731 9.32056 15.8226 9.99848 15.8226Z"
        fill="#FFA726"
      />
    </svg>
  );
};

export default WarningIcone;
