import React from "react";

const InvoiceSvg = () => {
  return (
    <svg
      height="20"
      width="20"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      className="sidebarIcons"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <g>
          <g>
            <path d="M256,64c-12.8,0-21.333,8.533-21.333,21.333v213.333c0,12.8,8.533,21.333,21.333,21.333 c12.8,0,21.333-8.533,21.333-21.333V85.333C277.333,72.533,268.8,64,256,64z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M256,170.667c-23.467,0-42.667-12.8-42.667-23.467s19.2-23.467,42.667-23.467c14.933,0,40.533,10.667,49.067,19.2 s21.333,8.533,29.867,0s8.533-21.333,0-29.867c-14.933-17.067-51.2-32-78.933-32c-44.8,0-85.333,25.6-85.333,66.133 S211.2,213.333,256,213.333c23.467,0,42.667,12.8,42.667,23.467c0,10.667-19.2,23.467-42.667,23.467 c-14.933,0-40.533-10.667-49.067-19.2c-8.533-8.533-21.333-8.533-29.867,0c-8.533,8.533-8.533,21.333,0,29.867 c14.933,17.067,53.333,32,78.933,32c44.8,0,85.333-25.6,85.333-66.133S300.8,170.667,256,170.667z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M320,81.067c-12.8,0-21.333,8.533-21.333,21.333v53.333c0,12.8,8.533,21.333,21.333,21.333s21.333-8.533,21.333-21.333 V102.4C341.333,89.6,332.8,81.067,320,81.067z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M192,209.067c-12.8,0-21.333,8.533-21.333,21.333v53.333c0,12.8,8.533,21.333,21.333,21.333s21.333-8.533,21.333-21.333 V230.4C213.333,217.6,204.8,209.067,192,209.067z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M386.133,0H121.6c-19.2,0-36.267,17.067-36.267,38.4v452.267c0,2.133,0,6.4,2.133,8.533 c2.133,4.267,6.4,8.533,10.667,10.667c2.133,2.133,6.4,2.133,8.533,2.133c2.133,0,8.533,0,10.667-2.133 c2.133-2.133,4.267-2.133,6.4-4.267l10.667-10.667l29.867,14.933c6.4,2.133,12.8,2.133,19.2,0l29.867-17.067l34.133,17.067 c6.4,2.133,12.8,2.133,19.2,0L300.8,492.8l34.133,17.067c6.4,2.133,12.8,2.133,19.2,0l25.6-14.933L390.4,505.6 c12.8,12.8,36.267,4.267,36.267-14.933V38.4C422.4,17.067,405.333,0,386.133,0z M379.733,448c-4.267,0-6.4,0-8.533,2.133 L341.333,467.2L307.2,450.133c-6.4-2.133-12.8-2.133-19.2,0L256,467.2l-34.133-17.067c-6.4-2.133-12.8-2.133-19.2,0l-32,17.067 l-34.133-17.067C134.4,448,132.267,448,128,448V42.667h251.733V448z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M234.667,341.333h-64c-12.8,0-21.333,8.533-21.333,21.333S157.867,384,170.667,384h64 c12.8,0,21.333-8.533,21.333-21.333S247.467,341.333,234.667,341.333z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M341.333,341.333H320c-12.8,0-21.333,8.533-21.333,21.333S307.2,384,320,384h21.333c12.8,0,21.333-8.533,21.333-21.333 S354.133,341.333,341.333,341.333z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default InvoiceSvg;
