import React from "react";

const WarningCircle = () => {
  return (
    <svg
      cursor="pointer"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12C4 7.59872 7.59872 4 12 4C16.4013 4 20 7.59872 20 12C20 16.4013 16.4013 20 12 20C7.59872 20 4 16.4013 4 12ZM12 5.11628C8.21523 5.11628 5.11628 8.21523 5.11628 12C5.11628 15.7848 8.21523 18.8837 12 18.8837C15.7848 18.8837 18.8837 15.7848 18.8837 12C18.8837 8.21523 15.7848 5.11628 12 5.11628Z"
        fill="#6A6D76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9993 15.7209C11.691 15.7209 11.4411 15.4711 11.4411 15.1628L11.4411 11.4419C11.4411 11.1336 11.691 10.8837 11.9993 10.8837C12.3075 10.8837 12.5574 11.1336 12.5574 11.4419L12.5574 15.1628C12.5574 15.4711 12.3075 15.7209 11.9993 15.7209Z"
        fill="#6A6D76"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7472 9.20931C12.7472 9.62031 12.414 9.95349 12.003 9.95349L11.9963 9.95349C11.5853 9.95349 11.2521 9.62031 11.2521 9.20931C11.2521 8.7983 11.5853 8.46512 11.9963 8.46512L12.003 8.46512C12.414 8.46512 12.7472 8.7983 12.7472 9.20931Z"
        fill="#6A6D76"
      />
    </svg>
  );
};

export default WarningCircle;
